/* ####### RPO Specific style #######*/

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {

    --rpo-white: #FFFFFF;
    --rpo-red: #CF2E2E;
    --rpo-light-red: #FFEBEB;
    --rpo-orange: #FF6900;
    --rpo-green: #027A48;
    --rpo-light-green: #ECFDF3;
    --rpo-yellow: #E0A501;
    --rpo-light-yellow: #FFF3D0;
    --rpo-blue: #8ED1FC;
    --rpo-light-blue: #29ABFF;
    --rpo-light-blue-2: #F8FCFF;
    --rpo-very-light-blue: #E4F5FF;
    --rpo-blue-purple: #3451F4;
    --rpo-dark-blue: #3538CD;
    --rpo-purple: #9C4FFF;
    --rpo-purple-2: #7F56D9;
    --rpo-purple-3: #B692F6;
    --rpo-light-purple: #F9F5FF;
    --rpo-very-light-purple: #EEF4FF;
    --rpo-dark-purple: #53389E;
    --rpo-pink: #F78DA7;
    --rpo-grey: #A2ABB4;
    --rpo-light-grey: #D2D8DE;
    --rpo-light-grey-2: #EAECF0;
    --rpo-light-grey-3: #F2F4F7;
    --rpo-light-grey-4: #667085;
    --rpo-dark-grey: #54595F;
    --rpo-black: #000000;

    --rpo-bg-color: #4550f7;

    --rpo-primary-gradient: linear-gradient(
        135deg,
        rgba(156, 79, 255, 1) 0%,
        rgba(41, 171, 255, 1) 100%
    );

    --rpo-shadow-intro-text: 0px 4px 6px rgba(16, 24, 40, 0.35);
    --rpo-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    --rpo-shadow-sm: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    --rpo-shadow-md: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    --rpo-shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),0px 10px 16px -4px rgba(16, 24, 40, 0.05);
    --rpo-shadow-xl: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    --rpo-shadow-2-xl: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    --rpo-shadow-3-xl: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);

    --bs-text-white: #FFFFFF;
    --bs-danger: #CF2E2E;
    /* --bs-warning: #FF6900; */
    --bs-warning: var(--rpo-purple);
    --bs-text-warning: #FF6900;
    --bs-warning-active: #F47923;
    --bs-success: #027A48;
    --bs-info: #9C4FFF;
    --bs-text-primary: #29ABFF;
    --bs-text-gray-200: #667085;
    --bs-text-gray-300: #A2ABB4;
    --bs-text-gray-600: #54595F;
    --bs-border-color: #D2D8DE;

    --bs-warning-rgb: 255,105,0;

    --bs-page-bg: #FAFAFA;
    --bs-secondary: #FAFAFA;

    --bs-gutter-x: 32px;
    --bs-gutter-y: 32px;

}

body, html {
    font-family: 'Be Vietnam Pro', sans-serif;  
}

body .page {
    max-width: 2200px;
}

body .aside .aside-menu .menu .menu-item:hover .menu-title,
body .aside .aside-menu .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
    color: var(--rpo-blue);
}

button.btn {
    border-radius: 2em;
}

body .card .card-header {
    min-height: unset;
    color: var(--rpo-dark-grey);
}

body .shadow-sm {
    box-shadow: var(--rpo-shadow-sm)!important;
}

body .shadow-lg {
    box-shadow: var(--rpo-shadow-lg)!important;
}

table.dataTable>thead .sorting_asc:after {
    mask-image: url('data:image/svg+xml,%3Csvg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M5.99998 1.33333V10.6667M5.99998 10.6667L10.6666 6M5.99998 10.6667L1.33331 6" stroke="%2354595F" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E');
}

table.dataTable>thead .sorting_desc:after {
    mask-image: url('data:image/svg+xml,%3Csvg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath transform="rotate(180 6 6)" d="M5.99998 1.33333V10.6667M5.99998 10.6667L10.6666 6M5.99998 10.6667L1.33331 6" stroke="%2354595F" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E');
}


/* ##################################*/


/* ######### RPO Selectors ##########*/

.bg-rpo {
    background-color: var(--rpo-bg-color);
    background-image: url('../media/rpo_aside_bg_1504x2555.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.rpo-bg-light-red {
    background-color: var(--rpo-light-red);
}

.rpo-border-1-r2em-red {
    border-radius: 2em;
    border: 1px solid var(--rpo-red);
}

.rpo-border-1-r8 {
    border-radius: 8px;
}
.rpo-border-1-r8-light-grey {
    border-radius: 8px;
    border: 1px solid var(--rpo-light-grey);
}

.rpo-border-1-r8-blue-purple {
    border-radius: 8px;
    border: 1px solid var(--rpo-blue-purple);
}

.rpo-border-1-r40-light-grey {
    border-radius: 40px;
    border: 1px solid var(--rpo-light-grey);
}

.rpo-border-1-r40-blue-purple {
    border-radius: 40px;
    border: 1px solid var(--rpo-blue-purple);
}

.rpo-svg-14px svg {
    width: 14px;
    height: 14px;
}

.rpo-svg-20px svg {
    width: 20px;
    height: 20px;
}

.rpo-svg-25px svg {
    width: 25px;
    height: 25px;
}

.rpo-svg-35px svg {
    width: 35px;
    height: 35px;
}

.rpo-bg-light-blue-2 {
    background-color: var(--rpo-light-blue-2);
}

.rpo-bg-blue-purple {
    background-color: var(--rpo-blue-purple);
}

.rpo-border-blue-purple {
    border-color: var(--rpo-blue-purple);
}

.border.rpo-border-blue-purple {
    border: var(--bs-border-width) var(--bs-border-style) var(--rpo-blue-purple)!important;
}

.form-control.form-control-solid.MuiInputBase-root.rpo-selected-border-blue-purple:active,
.form-control.form-control-solid.MuiInputBase-root.rpo-selected-border-blue-purple:focus{
    border: var(--bs-border-width) var(--bs-border-style) var(--rpo-blue-purple)!important;
}

.rpo-fill-transparent svg,
.rpo-fill-transparent svg path {
    fill: transparent;
}

.rpo-fill-white svg,
.rpo-fill-white svg path {
    fill: var(--rpo-white);
}

.rpo-fill-green svg,
.rpo-fill-green svg path {
    fill: var(--rpo-green);
}

.rpo-fill-red svg,
.rpo-fill-red svg path {
    fill: var(--rpo-red);
}

.rpo-fill-orange svg,
.rpo-fill-orange svg path {
    fill: var(--rpo-orange);
}

.rpo-fill-purple svg,
.rpo-fill-purple svg path {
    fill: var(--rpo-purple);
}

.rpo-fill-blue svg,
.rpo-fill-blue svg path {
    fill: var(--rpo-light-blue);
}

.rpo-fill-grey svg,
.rpo-fill-grey svg path {
    fill: var(--rpo-grey);
}

.rpo-stroke-path-grey svg path {
    stroke: var(--rpo-dark-grey);
}

.rpo-stroke-path-orange svg path {
    stroke: var(--rpo-orange);
}

.rpo-stroke-path-green svg path {
    stroke: var(--rpo-green);
}

.rpo-stroke-path-red svg path {
    stroke: var(--rpo-red);
}

.rpo-plus-icon svg {
    border: 1px var(--rpo-orange) dotted;
    border-radius: 100%;
    padding: 3px;
}

.rpo-file-icon {
    width: 28px;
    height: 28px;
    padding: 4px 0px 0px 6px;
    border-radius: 100%;
    background: var(--rpo-light-purple);
}

.rpo-check-icon {
    width: 19px;
    height: 18px;
    padding: 0px 0px 0px 4px;
    border-radius: 100%;
    background: var(--rpo-purple);
}

.rpo-check-icon svg {
    fill: var(--rpo-purple);
}

.rpo-fill-first-orange-other-white svg path {
    fill: var(--rpo-white);
}

.rpo-fill-first-orange-other-white svg path:nth-child(1) {
    fill: var(--rpo-orange);
}

.rpo-badge-purple {
    color: var(--rpo-purple);
    background-color: var(--rpo-light-purple);
}
.rpo-badge-blue {
    color: var(--rpo-blue-purple);
    background-color: var(--rpo-very-light-blue);
}
.rpo-badge-light-blue {
    color: var(--rpo-light-blue);
    background-color: var(--rpo-very-light-blue);
}
.rpo-badge-light-purple {
    color: var(--rpo-dark-blue);
    background-color: var(--rpo-very-light-purple);
}
.rpo-badge-grey {
    color: var(--rpo-dark-grey);
    background-color: var(--rpo-light-grey);
}

.rpo-badge-orange {
    color: var(--rpo-orange);
    background-color: var(--rpo-light-orange);
}

.rpo-badge-border-pill {
    border-radius: 0.8rem;
}

.rpo-symbol-ligh-purple {
    background-color: var(--rpo-light-purple);
}

.rpo-text-blue-purple {
    color: var(--rpo-blue-purple);
}

.rpo-text-shadow-intro {
    text-shadow: var(--rpo-shadow-intro-text);
}

.rpo-fs-intro {
    font-size: 38px;
}

.rpo-fw-200 {
    font-weight: 200;
}

body .aside.bg-rpo {
    top: unset;
    bottom: unset;
}

body .aside.card {
    height: calc(100vh - 64px);
    display: inline-flex;
}

body .aside.card .aside-menu {
    height: 41px;
    overflow-y: scroll;
}

.rpo-clickable {
    cursor: pointer;
}

.rpo-input,
.MuiInputBase-root.rpo-select,
.rpo-input-group {
    border: 1px solid var(--rpo-light-grey);
    border-radius: 4em;
}

.rpo-input::placeholder {
    font-weight: 400;
}

.rpo-input::-ms-input-placeholder {
    font-weight: 400;
}

.rpo-input-icon {
    padding-left: 45px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: 15px;
    background-position-y: 10px;
}

.rpo-input-icon-mail {
    background-image: url(../media/mail_icon_20x20.png);
}

.rpo-input-icon-globe {
    background-image: url(../media/globe_icon_20x20.png);
}

.rpo-select-icon-arrow-down {
    background-image: url(../media/arrow_down_icon_20x20.png);
    background-repeat: no-repeat;
    background-position: 96%;
    cursor: pointer;
}

.MuiInputBase-root.rpo-select {
    background-image: url(../media/arrow_down_icon_20x20.png);
    background-size: 20px;
    background-repeat: no-repeat;
    padding: initial;
}

.MuiInputBase-root.rpo-select:has(>.MuiSelect-select[aria-expanded="true"]) {
    background-image: unset;
}

.MuiInputBase-root.rpo-select .MuiSelect-select {
    padding-top: 0.825rem;
    padding-bottom: 0.825rem;
    padding-left: 1.5rem;
}

.MuiInputBase-root.rpo-select .MuiOutlinedInput-notchedOutline {
    border: 0px solid var(--rpo-light-grey);
    border-radius: 4em;
}

.MuiInputBase-root.rpo-select .MuiSelect-iconOutlined{
    display: none;
}

.rpo-textarea-no-resize {
    resize: none;
}

.rpo-min-w-128px {
    min-width: 128px;
}

.rpo-min-h-52px {
    min-height: 52px;
}

.rpo-width-13em {
    width: 13em;
}

.rpo-rotate-90 {
    transform: rotate(180deg);
}

.rpo-last-child-no-margin-no-padding > :last-child {
    margin: unset;
    padding: unset;
}

.btn.rpo-button-padding:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding-top:15px;
    padding-bottom:15px;
    padding-left:16px;
    padding-right:18px;
}

.rpo-ps-16 {
    padding-left: 16px!important;
}

.rpo-pe-16 {
    padding-right: 16px!important;
}

.rpo-px-32,
.rpo-ps-32 {
    padding-left: 32px!important;
}

.rpo-px-32,
.rpo-pe-32 {
    padding-right: 32px!important;
}

.rpo-pt-32 {
    padding-top: 32px!important;
}

.rpo-pb-32 {
    padding-bottom: 32px!important;
}

.rpo-left-32 {
    left: 32px!important;
}

.rpo-wrapper-fuild-padding {
    padding-left: calc(315px - 18px);
}

/* ##################################*/


/* ###### RPO Fixed Selectors #######*/

.rpo-search-bar-client-container,
.rpo-search-bar-admin-container {
    display: flex;
    padding: 8px 24px 8px 14px;
    align-items: center;
    gap: 14px;
    flex-shrink: 0;
}

.rpo-search-bar-client-container {
    width: 308px;
}

.rpo-search-bar-admin-container {
    width: 360px;
}

.rpo-btn-orange-bg {
    display: inline-flex;
    height: 40px;
    padding: 15px 18px 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 30px;
    background-color: var(--rpo-orange);
    border: 1px solid var(--rpo-orange);
    font-size: 12px;
    font-weight: 400;
    color: var(--rpo-white);
}

.rpo-btn-orange-bg svg,
.rpo-btn-orange-bg svg path {
    fill: var(--rpo-white);
}

.rpo-btn-orange-bg:hover {
    background-color: var(--rpo-white);
    color: var(--rpo-orange);
    transition: background-color 0.1s, color 0.1s;
}

.rpo-btn-orange-bg:hover svg,
.rpo-btn-orange-bg:hover svg path {
    fill: var(--rpo-orange);
    transition: background-color 0.3s, color 0.3s;
}

.rpo-btn-purple-bg {
    display: inline-flex;
    height: 40px;
    padding: 15px 18px 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 30px;
    background-color: var(--rpo-purple);
    border: 1px solid var(--rpo-purple);
    font-size: 12px;
    font-weight: 400;
    color: var(--rpo-white);
}

.rpo-btn-purple-bg svg,
.rpo-btn-purple-bg svg path {
    fill: var(--rpo-white);
}

.rpo-btn-purple-bg:hover {
    background-color: var(--rpo-white);
    color: var(--rpo-purple);
    transition: background-color 0.1s, color 0.1s;
}

.rpo-btn-purple-bg:hover svg,
.rpo-btn-purple-bg:hover svg path {
    fill: var(--rpo-purple);
    transition: background-color 0.3s, color 0.3s;
}


.rpo-btn-white-bg {
    display: inline-flex;
    height: 40px;
    padding: 15px 18px 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 30px;
    background-color: var(--rpo-white);
    border: 1px solid var(--rpo-orange);
    font-size: 12px;
    font-weight: 400;
    color: var(--rpo-orange);
}

.rpo-btn-white-bg svg,
.rpo-btn-white-bg svg path {
    fill: var(--rpo-white);
}

.rpo-btn-white-bg:hover {
    background-color: var(--rpo-orange);
    color: var(--rpo-white);
    transition: background-color 0.1s, color 0.1s;
}

.rpo-btn-white-bg:hover svg,
.rpo-btn-white-bg:hover svg path {
    fill: var(--rpo-white);
    transition: background-color 0.3s, color 0.3s;
}

.rpo-btn-grey-bg {
    display: inline-flex;
    height: 40px;
    padding: 15px 18px 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 30px;
    background-color: var(--rpo-white);
    border: 1px solid var(--rpo-grey);
    font-size: 12px;
    font-weight: 400;
    cursor: unset !important;
}

.aside .aside-menu .menu .menu-item .menu-link.rpo-main-menu-item .menu-title {
    color: var(--rpo-white);
}

.rpo-main-menu-item svg,
.rpo-main-menu-item svg path {
    fill: var(--rpo-white);
}

.aside .aside-menu .menu .menu-item .menu-link.rpo-main-menu-item.selected .menu-title,
.rpo-main-menu-item:hover .menu-title {
    color: var(--rpo-blue);
}

.rpo-main-menu-item.selected svg,
.rpo-main-menu-item.selected svg path,
.rpo-main-menu-item:hover svg,
.rpo-main-menu-item:hover svg path {
    fill: var(--rpo-blue);
}

.aside .aside-menu .menu .menu-item .menu-link.rpo-main-menu-item:hover,
.aside .aside-menu .menu .menu-item .menu-link.rpo-main-menu-item.selected  {
    border-right: 4px solid var(--rpo-blue);
    border-radius: unset;
}

.rpo-vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: var(--rpo-blue-purple);
}

.dashboard-user-card-container {
    min-height: 87px;
}

.dashboard-user-card-container .card {
    position: fixed;
    z-index: 30;
    width: calc(100% - 361px);
    max-width: calc(2200px - 361px);
}

/* ##################################*/


/* ####### Datatable Selectors ######*/

.rpo-datatable-border .card {
    border: 1px solid var(--rpo-light-grey);
}

.rpo-datatable-border table.dataTable thead tr,
.rdt_TableHead .rdt_TableHeadRow {
    background-color: var(--bs-page-bg);
}

.rpo-datatable-border.rpo-datatable-transparent-header table.dataTable thead tr {
    background-color: transparent;
}

.rpo-datatable-border table.dataTable th,
.rpo-datatable-border table.dataTable td {
    border-bottom: 1px solid var(--rpo-light-grey);
    padding: 1em 0px;
}

.rpo-datatable-border table.dataTable tbody tr:last-child td {
    border: none;
    padding-bottom: unset;
}

.rpo-datatable-border table.dataTable tr th:first-child,
.rpo-datatable-border table.dataTable tr td:first-child {
    padding-left: 2em;
}

.rpo-datatable-border table.dataTable tr th:last-child,
.rpo-datatable-border table.dataTable tr td:last-child {
    padding-right: 2em;
}

.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell {
    padding-bottom: unset;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol:first-child,
.rdt_Table .rdt_TableBody .rdt_TableRow  .rdt_TableCell:first-child {
    padding-left: 2.5em;
}
/* ##################################*/


/* ######### Chart Selectors ########*/

.rpo-chart-legend-top-right .apexcharts-legend {
    justify-content: end;
    margin-top: 2%;
}

/* ##################################*/


/* ####### Slider Selectors #######*/
.dashboard-user-card-container .card {
    border-radius: 8px;
    border: 1px solid var(--rpo-light-grey);
    background-color: var(--rpo-white);
}
.dashboard-user-card-container:hover .card {
    border-radius: 8px;
    border: 1px solid var(--rpo-blue-purple);
    background-color: var(--rpo-light-blue-2);
}

.dashboard-user-card-container:hover .vr {
    opacity: 1;
    background-color: var(--rpo-blue-purple);
}

.dashboard-user-card-container:hover .rpo-profile-displayname {
    color: var(--rpo-black);
}
.dashboard-user-card-container:hover .rpo-profile-displayname {
    color: var(--rpo-blue-purple);
}

/* ################################*/


/* ####### Slider Selectors ######*/

.rpo-noUiSlider {
    background-color: var(--rpo-light-grey-2);
}

.rpo-noUiSlider.noUi-target .noUi-connect {
    background-color: var(--rpo-purple-2);
}

.rpo-noUiSlider .noUi-tooltip {
    bottom: unset;
    border: unset;
    box-shadow: unset;
}

.rpo-noUiSlider .noUi-touch-area {
    cursor: pointer;
}

/* ##################################*/


/* ####### MuiMenu Selectors ######*/

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: 'Be Vietnam Pro', sans-serif;
}

/* ##################################*/



/* ####### Form Selectors ######*/

.pac-container.pac-logo {
    background: #f9f9f9;
    box-shadow: none;
    border-radius: 0px 0em 1em 1em;
    padding: 1.5em 1em 0.5em 1em;
    margin-top: -0.5em;
    border: unset;
}

/* ##################################*/



/* ####### Loading Spinner ######*/

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid var(--rpo-white);
    border-color: var(--rpo-white) transparent var(--rpo-white) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    /* position: absolute; */
    position: fixed;
    top: 0;
    left: 0;
    background: var(--rpo-blue-purple);
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 40;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

/* ##################################*/

/* ####### Loading Spinner ######*/

.rpo-item-light-grey-bg {
    background-color: var(--rpo-light-grey-3);
}

.rpo-item-light-grey-bg:hover {
    background-color: var(--rpo-orange);
    color: var(--rpo-white);
    transition: background-color 0.1s, color 0.1s;
}

/* ##################################*/


/* ####### Responsive ######*/


@media only screen and (max-width: 992px) {
    .rpo-wrapper-fuild-padding {
        padding-left: unset;
    }
}

/* ##################################*/